@tailwind base;
@tailwind components;
@tailwind utilities;


.ql-editor {
    font-size: 25px;
  }

  .ant-upload-list-item-name {
    white-space: nowrap;          /* Tek satırda tut */
    overflow: hidden;             /* Taşan kısmı gizle */
    text-overflow: ellipsis;      /* Taşan kısmı "..." ile göster */
    max-width: 100px;             /* Maksimum genişlik */
    display: inline-block;        /* Metnin bir satırda kalmasını sağlar */
  }