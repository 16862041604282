/* --- CSS Değişkenleri ve Temel Stil Ayarları --- */
:root {
  --primary-color: #004785;
  --primary-light: #005ca8;
  --primary-dark: #003d70;
  --text-primary: #2d3748;
  --text-secondary: #4a5568;
  --background-light: #ffffff; /* Değiştirildi: #f7fafc'den #ffffff'e */
  --card-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --transition-default: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  /* Header Renkleri */
  --header-primary-blue: #235390;
  --header-danger-red: #dc3545;
  --header-success-green: #28a745;
  --header-yellow-color: #FFF8DC;
}

/* --- Seçenekler Bölümü --- */
#options-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 Eşit Sütun */
  gap: 2rem;
  max-width: 1200px;
  margin: 3rem auto;
  padding: 0 2rem;
}

/* Responsive Tasarım */
@media (max-width: 992px) {
  #options-section {
    grid-template-columns: repeat(2, 1fr); /* 2 Sütun */
  }
}

@media (max-width: 768px) {
  #options-section {
    grid-template-columns: 1fr; /* 1 Sütun */
  }
}

/* --- Option Box Stilleri --- */
.option-box {
  position: relative;
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  height: 280px;
  text-decoration: none;
  color: var(--text-primary);
  transition: var(--transition-default);
  display: flex;
  flex-direction: column;
  box-shadow: var(--card-shadow);
  overflow: hidden;
  border-left: 5px solid transparent;
}

.option-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Her Bir Option Box İçin Arka Plan Renkleri ve İkonlar */
.option-box-1 {
  background: rgba(220, 53, 69, 0.05);
  border-left-color: var(--header-danger-red);
}

.option-box-1 .option-icon {
  background: var(--header-danger-red);
}

.option-box-1 .option-title,
.option-box-1 .card-arrow {
  color: var(--header-danger-red);
}

.option-box-2 {
  background: rgba(35, 83, 144, 0.05);
  border-left-color: var(--header-primary-blue);
}

.option-box-2 .option-icon {
  background: var(--header-primary-blue);
}

.option-box-2 .option-title,
.option-box-2 .card-arrow {
  color: var(--header-primary-blue);
}

.option-box-3 {
  background: rgba(255, 248, 220, 0.5);
  border-left-color: #ffc107;
}

.option-box-3 .option-icon {
  background: #ffc107; /* Daha koyu bir sarı ton */
}

.option-box-3 .option-icon i {
  color: #ffffff; /* İkon rengini beyaz yapalım */
}

.option-box-3 .option-title,
.option-box-3 .card-arrow {
  color: #ffc107;
}

.option-box-4 {
  background: rgba(40, 167, 69, 0.05);
  border-left-color: var(--header-success-green);
}

.option-box-4 .option-icon {
  background: var(--header-success-green);
}

.option-box-4 .option-title,
.option-box-4 .card-arrow {
  color: var(--header-success-green);
}

/* --- İkon Stilleri --- */
.option-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  transition: var(--transition-default);
}

.option-icon i {
  font-size: 1.75rem;
  color: white;
}

.option-box:hover .option-icon {
  transform: scale(1.15) rotate(-10deg);
}

/* --- Metin Stilleri --- */
.option-title {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
}

.option-description {
  font-size: 1rem;
  color: var(--text-secondary);
  margin: 0;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.card-arrow {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
  opacity: 0;
  transform: translateX(-20px);
  transition: var(--transition-default);
}

.option-box:hover .card-arrow {
  opacity: 1;
  transform: translateX(0);
}

.card-arrow i {
  margin-left: 0.5rem;
  transition: var(--transition-default);
}

.option-box:hover .card-arrow i {
  transform: translateX(8px);
}

/* --- Arka Plan Hover Efektleri --- */
.option-box-1:hover {
  background: rgba(220, 53, 69, 0.1);
}

.option-box-2:hover {
  background: rgba(35, 83, 144, 0.1);
}

.option-box-3:hover {
  background: rgba(255, 193, 7, 0.2); /* Hover durumunda daha canlı sarı */
}

.option-box-4:hover {
  background: rgba(40, 167, 69, 0.1);
}

/* --- Option Overlay (Eğer gerekiyorsa) --- */
.option-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}
