/* Genel Stiller */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
}

/* Tema Listesi İçeriği */
#tema-listesi-content {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
    text-align: center;
}

#tema-listesi-content h2 {
    margin-bottom: 2rem;
    font-size: 2rem;
    color: #003366;
}

/* Tema Grid */
.tema-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
}

/* Tema Kartları */
.theme-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
    position: relative;
    overflow: hidden;
}

.theme-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    background-color: #e0f7fa; /* Hover durumunda arka plan rengi değişir */
}

.theme-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 50%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.5), transparent);
    transform: skewX(-25deg);
    transition: left 0.3s ease;
}

.theme-card:hover::before {
    left: 150%;
}

.theme-card img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    margin-bottom: 1rem;
    border-radius: 4px;
}

.theme-title {
    font-size: 1.1rem;
    color: #003366;
    font-weight: bold;
}

/* Responsive Tasarım */
@media (max-width: 1200px) {
    .tema-grid {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (max-width: 992px) {
    .tema-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 768px) {
    .tema-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    #search-input {
        width: 70%;
    }
}

@media (max-width: 576px) {
    .tema-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    #search-input {
        width: 100%;
        margin-bottom: 1rem;
    }

    .search-container {
        flex-direction: column;
        align-items: stretch;
    }
}

