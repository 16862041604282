  
  /* --- Arama Sonuçları ve Söz Ailesi --- */
  #result-wrapper {
    display: flex;
    flex-wrap: wrap; /* nowrap yerine wrap kullanıldı */
    gap: 80px; /* Değiştirildi: 40px'den 5px'e */
    justify-content: flex-start; /* space-between yerine flex-start */
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: 50px; 
    padding-right: 10px; /* Değiştirildi: 20px'den 5px'e */
  }
  
  /* Ana Arama Sonuçları */
  #search-results {
    flex: 1;
    min-width: 300px;
    max-width: 50%; /* Değiştirildi: 65% -> 50% */
    margin-right: 5px; /* Değiştirildi: 20px'den 5px'e */
    margin-left: auto; /* Sola doğru boşluk bırakmak için eklendi */
  }
  
  /* Söz Ailesi Ana Container */
  #soz-ailesi-ana-container {
    flex: 1;
    min-width: 200px;
    max-width: 25%; /* Genişlik sınırlaması */
    margin-right: 5px; /* Değiştirildi: 40px'den 5px'e */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px; /* Değiştirildi: 20px'den 10px'e */
    /* width: 75%; */ /* Bu satır kaldırıldı */
    padding: 10px; /* Değiştirildi: 20px'den 10px'e */
    box-sizing: border-box;
  }
  
  /* Her Bir Bölümün Aynı Boyutta Olmasını Sağla */
  #soz-ailesi-ana-container .soz-ailesi,
  #soz-ailesi-ana-container .turkce-ogren,
  #soz-ailesi-ana-container .tema-listesi {
    width: 100%; /* Container içinde %100 genişlikte */
    box-sizing: border-box;
  }
  
  /* --- Madde Bloku --- */
  .madde-bloku {
    width: 100%; /* Tam genişlik */
    margin: 0 10px 25px 0; /* Sağ margin 20px'den 10px'e indirildi */
  }
  
  /* Madde Başı ve Ses İkonu Ortalamak */
  .madde-basi {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .madde-basi h2 {
    color: #1F2953; /* Değiştirildi: #000000'dan #1F2953'e */
    font-size: 2.6rem;
    line-height: 1.2;
    margin: 0;
  }
  
  .sozycuk-turu {
    font-size: 1rem;
    color: #888;
    margin: 0;
    text-align: center;
  }
  
  .sozycuk-turu-separator {
    color: #1F2953; /* Değiştirildi: #333'dan #1F2953'e */
    margin: 0 5px;
  }
  
  /* --- Play Button --- */
  .play-button {
    background-size: contain;
    width: 52px;
    height: 52px;
    border: none;
    cursor: pointer;
    display: block;
    margin: 10px auto;
    vertical-align: middle;
    margin-top: -5px;
    margin-bottom: -5px;
  }
  
  .play-button:hover {
    opacity: 0.8;
  }
  
  /* --- Anlam Bloku --- */
  .anlam-bloku {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .anlam-content {
    flex: 1 1 60%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
  }
  
  /* Anlam Görseli */
  .anlam-gorsel {
    flex: 0 0 100px;
    width: 100px;
  }
  
  /* .sozcuk-bicimi Sınıfını Güncelleme */
  .sozcuk-bicimi {
    margin: 5px 0; /* Boşlukları isteğinize göre ayarlayabilirsiniz */
    max-width: 100%;
    line-height: 1.5em;
    display: flex;
    align-items: center; /* veya flex-start */
  }
  
  .sozcuk-bicimi-content {
    max-height: 1.5em;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .sozcuk-bicimi-content a {
    text-decoration: none;  /* Altı çizili olmasını engeller */
    color: inherit;         /* Metin rengini çevresindeki metinle aynı yapar */
  }
  .sozcuk-bicimi-content a:hover {
    color: #dc3545;           /* Kırmızı renk */
    text-decoration: underline; /* Altı çizili olsun */
  }
  
  .sozcuk-bicimi-content.expanded {
    max-height: none;
  }
  
  .sozcuk-bicimi-content p {
    margin: 0;
    color: #003366;
  }
  
  /* Markdown benzeri stiller */
  .sozcuk-bicimi-content p strong {
    font-weight: bold;
  }
  
  .sozcuk-bicimi-content p em {
    font-style: italic;
  }
  
  /* .toggle-button Sınıfını Güncelleme */
  .toggle-button {
    background: none;
    border: none;
    color: #dc3545;
    font-size: 1em;
    cursor: pointer;
    padding: 0;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }
  
  .toggle-button:hover {
    transform: translateY(2px);
  }
  
  .toggle-button::before {
    content: '\25BC';
    transition: transform 0.3s ease;
  }
  
  .toggle-button.expanded::before {
    content: '\25B2';
  }
  
  /* --- Separator Line ve "Listeme ekle" Butonu --- */
  .separator-line {
    display: flex;
    align-items: center;
    margin-top: 5px; /* Dikey boşluklar azaltıldı */
    position: relative;
  }
  
  .separator-line::before {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: #dc3545;
    margin-right: 10px; /* Margin değeri değişmedi */
  }
  
  .add-to-list-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 1px 8px;
    font-size: 10px;
    line-height: 1;
    cursor: pointer;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    z-index: 1;
    transition: background-color 0.3s, color 0.3s;
    font-weight: normal;
  }
  
  .add-to-list-button:hover {
    background-color: #c82333;
  }
  
  .add-to-list-button .list-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  
  .add-to-list-button .list-icon svg {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: white; /* Rengi beyaz olarak ayarladım */
    stroke-width: 2;
  }
  
  .add-to-list-text {
    margin-left: 5px;
  }
  
  /* --- Örnek Cümleler Bölümü --- */
  .anlam-bloku .ornek-cumle {
    padding-left: 20px;
    margin: 5px 0;
  }
  
  .anlam-bloku .ornek-cumle ul {
    list-style-type: none; /* Madde işaretlerini kaldırır */
    padding-left: 0;        /* Sol padding'i kaldırır */
    margin: 0;
  }
  
  .anlam-bloku .ornek-cumle p,
  .anlam-bloku .ornek-cumle li {
    font-style: italic;
    color: #1F2953; /* Değiştirildi: #666'dan #1F2953'e */
    margin-bottom: 10px;
    font-size: 1rem;
    line-height: 1.1;
    background: none !important;
    background-color: transparent !important;
  }
  
  .anlam-bloku .ornek-cumle a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
    background: none !important;
    background-color: transparent !important;
  }
  
  .anlam-bloku .ornek-cumle a:hover {
    color: #dc3545;
    text-decoration: none;
  }
  
  /* --- Arama Sonuçları ve Söz Ailesi İçeriği --- */
  .sozcugun-anlami {
    display: block;
    width: 100%;
    color: #1F2953 !important; /* Değiştirildi: #000000'dan #1F2953'e */
    font-weight: 600 !important;
    font-size: 1.1rem !important;
    line-height: 1.3 !important;
    background-color: transparent !important;
    background: none !important;
  }
  
  .sozcugun-anlami p {
    margin: 0;
    width: 100%;
    color: #1F2953 !important; /* Değiştirildi: #000000'dan #1F2953'e */
    font-weight: 600 !important;
    font-size: 1.1rem !important;
    line-height: 1.3 !important;
    background-color: transparent !important;
    background: none !important;
  }
  
  /* --- Söz Ailesi Başlığı ve Listeleri --- */
  #soz-ailesi-ana-container .soz-ailesi h3 {
    color: #1F2953; /* Mavi renk */
    border-bottom: 2px solid #1F2953; /* Mavi alt çizgi */
  }
  
  #soz-ailesi-ana-container .soz-ailesi li {
    color: #1F2953; /* Söz Ailesi listeleri için mavi renk */
  }
  
  #soz-ailesi-ana-container .soz-ailesi a {
    color: #1F2953; /* Söz Ailesi içindeki linkler için mavi renk */
  }
  
  /* --- Türkçe Öğren Kartı Stilleri --- */
  .turkce-ogren {
    width: 100%;
    margin: 0 0 10px 0;
    background-color: #f7d3e3;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(220, 53, 69, 0.1);
    transition: background-color 0.3s ease;
    overflow: hidden;
  }
  
  .turkce-ogren:hover {
    background-color: #f45f76;
  }
  
  .turkce-ogren-baslik h3 {
    color: #003366;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.3rem;
    line-height: 1.4;
    text-align: center;
    cursor: pointer;
  }
  
  .turkce-ogren-baslik h3:hover {
    color: #ffd700;
  }
  
  .turkce-ogren-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
  }
  
  .turkce-ogren-link {
    text-decoration: none;
    color: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .turkce-ogren-image {
    width: 100%;
    max-width: 250px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
    transition: transform 0.3s ease;
  }
  
  .turkce-ogren-link:hover .turkce-ogren-image {
    transform: scale(1.02);
  }
  
  /* --- Beceriler Grid --- */
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 10px;
    padding: 10px;
    margin: 10px auto;
    max-width: 250px;
    width: 100%;
    box-sizing: border-box;
    justify-items: center;
    animation: fadeInUp 0.5s ease-out;
  }
  
  .skill-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .skill-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .skill-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ffd700; /* Geri Alındı: #1F2953'dan #ffd700'a */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    margin-bottom: 6px;
  }
  
  .skill-circle:hover {
    transform: scale(1.1) rotate(10deg);
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
    background: #ff6347;
  }
  
  .skill-icon {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
  
  .skill-name {
    font-size: 1rem;
    color: #1F2953; /* Değiştirildi: #003366'dan #1F2953'e */
    font-weight: 600;
    text-align: center;
    transition: color 0.3s ease;
  }
  
  .skill-name:hover {
    color: #dc3545;
  }
  
  /* --- Animasyonlar --- */
  @keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
  }
  
  /* --- Tema Listesi Stilleri --- */
  .tema-listesi {
    width: 100%;
    margin: 0 0 10px 0;
    background-color: #d4faff; /* Geri Alındı: #1F2953'dan #d4faff'e geri alındı */
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(220, 53, 69, 0.1);
    transition: background-color 0.3s ease;
    overflow: hidden;
  }
  
  .tema-listesi:hover {
    background-color: #80deea;
  }
  
  /* Tema Listesi Başlık Stilleri */
  .tema-listesi-baslik {
    text-decoration: none;
    display: block;
    text-align: center;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
  }
  
  .tema-listesi-baslik:hover {
    transform: translateY(-2px);
  }
  
  .tema-listesi-baslik h3 {
    color: #1F2953; /* Mavi renk */
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0;
    padding: 10px 0;
    position: relative;
    display: inline-block;
  }
  
  .tema-listesi-baslik h3::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, #1F2953, #80deea); /* Mavi alt çizgi */
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }
  
  .tema-listesi-baslik:hover h3::after {
    transform: scaleX(1);
  }
  
  /* --- Tema Grid --- */
  .tema-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }
  
  .tema-card {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
    overflow: hidden;
  }
  
  .tema-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    background-color: #e0f7fa;
  }
  
  .tema-card img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    margin-bottom: 0.5rem;
    border-radius: 4px;
  }
  
  .theme-title {
    font-size: 1rem;
    color: #1F2953; /* Mavi renk */
    font-weight: bold;
    transition: color 0.2s ease;
  }
  
  .theme-title:hover {
    color: #dc3545;
  }
  
  /* Responsive Ayarlar */
  @media (max-width: 768px) {
    .turkce-ogren {
        max-width: 350px;
    }
  
    .skills-grid {
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    }
  
    .skill-item {
        max-width: 100px;
    }
  
    .skill-circle {
        width: 60px;
        height: 60px;
        padding: 8px;
    }
  
    .skill-icon {
        width: 50%;
        height: 50%;
    }
  
    .skill-name {
        font-size: 1rem;
    }
  
    /* Anlam Bloku */
    .anlam-bloku {
        flex-direction: column;
    }
  
    .anlam-content {
        margin-right: 0;
    }
  
    .anlam-gorsel {
        width: 100%;
        margin-top: 15px;
    }
  
    .gorsel-container {
        width: 80px;
        margin: 0 auto;
    }
  
    .gorsel {
        max-height: 80px;
    }
  
    .turkce-ogren-image {
        max-width: 200px;
    }
  
    /* Madde Bloku */
    .madde-bloku {
        margin: 0 10px 25px 0; /* Sağ margin 10px */
    }
  
    /* Söz Ailesi İçin */
    .soz-ailesi a {
      color: #1F2953; /* Mavi renk */
    }
  }
  
  @media (max-width: 480px) {
    .skills-grid {
        padding: 10px 20px;
        grid-gap: 15px;
        max-width: 240px;
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    }
  
    .skill-item {
        max-width: 90px;
    }
  
    .skill-circle {
        width: 50px;
        height: 50px;
        padding: 6px;
    }
  
    .skill-icon {
        width: 50%;
        height: 50%;
    }
  
    .skill-name {
        font-size: 0.9rem;
    }
  
    /* Madde Başı */
    .madde-basi h2 {
        font-size: 1.8rem;
        color: #1F2953; /* Mavi renk */
    }
  
    /* Play Button */
    .play-button {
        width: 35px;
        height: 35px;
    }
  
    /* Toggle Button */
    .toggle-button {
        width: 20px;
        height: 20px;
        font-size: 16px;
    }
  
    /* Örnek Cümleler */
    .anlam-bloku .ornek-cumle p,
    .anlam-bloku .ornek-cumle li {
        font-size: 0.85rem;
        color: #1F2953; /* Mavi renk */
    }
  
    /* Görsel Konteyneri */
    .gorsel-container {
        width: 60px;
    }
  
    .gorsel {
        max-height: 60px;
    }
  
    /* "Listeme ekle" Butonu */
    .add-to-list-button {
        padding: 3px 8px; /* Küçük ekranlarda daha da azaltıldı */
        font-size: 12px;
    }
  
    .add-to-list-button .list-icon {
        width: 14px;
        height: 14px;
    }
  
    /* Dil Seviyesi */
    .dil-seviyesi {
        font-size: 14px;
        padding: 2px 6px 2px 5px;
    }
  
    /* Türkçe Öğren Kartı */
    .turkce-ogren-image {
        max-width: 180px;
    }
  
    .turkce-ogren-info p {
        font-size: 0.9rem;
    }
  
    /* Tema Listesi Responsive */
    .tema-listesi {
        width: 100%;
        margin-left: 0;
    }
  
    .tema-grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
    }
  
    .tema-card img {
        height: 60px;
    }
  
    /* Söz Ailesi İçin */
    .soz-ailesi a {
      color: #1F2953; /* Mavi renk */
    }
  }
  
  /* --- Diğer Stil Kuralları --- */
  .sozluk-link {
    color: inherit !important;
    font-weight: inherit !important;
    font-style: inherit !important;
  }
  
  .sozluk-link:hover {
    color: #dc3545 !important;
  }
  
  /* --- Tematik Seçimler --- */
  .tematik-secimler {
    font-size: 14px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
  }
  
  .tematik-secim-link {
    display: inline-block;
    margin-right: 10px;
    background-color: #d4faff;
    padding: 5px 10px;
    border-radius: 3px;
    color: #1F2953; /* Mavi renk */
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tematik-secim-link:hover {
    background-color: #80deea;
    color: #000;
  }
  
  /* --- Söz Ailesi --- */
  .soz-ailesi {
    width: 100%;
    margin: 0 0 10px 0;
    background-color: #fff; /* Geri Alındı: #1F2953'dan #fff'e */
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(220, 53, 69, 0.1);
  }
  
  .soz-ailesi h3 {
    color: #1F2953; /* Mavi renk */
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.5rem;
    line-height: 1.4;
    border-bottom: 2px solid #1F2953; /* Mavi alt çizgi */
    padding-bottom: 5px;
  }
  
  .soz-ailesi p {
    color: #1F2953; /* Mavi renk */
    margin: 5px 0;
    font-weight: bold;
    font-size: 1.1rem;
  }
  
  .soz-ailesi ul {
    list-style-type: none;
    padding: 0;
  }
  
  .soz-ailesi li {
    margin: 5px 0;
  }
  
  .soz-ailesi a {
    text-decoration: none;
    color: #1F2953; /* Mavi renk */
    font-weight: normal;
    transition: color 0.3s;
  }
  
  .soz-ailesi a:hover {
    text-decoration: underline;
    color: #dc3545;
  }
  
  /* --- Dil Seviyesi ve Sözcüğün Anlamı Hizalaması --- */
  .dil-seviyesi {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 1px 6px 2px 6px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 10px 0;
    text-decoration: none;
    cursor: pointer;
    transition: opacity 0.3s ease;
    height: 16px;
    min-width: 45px;
    align-self: flex-start;
  }
  
  .dil-seviyesi.a1 {
    background-color: #003366;
  }
  
  .dil-seviyesi.a2 {
    background-color: #2e8b57;
  }
  
  .dil-seviyesi:hover {
    opacity: 0.8;
  }
  
  /* --- Görsel Konteyneri --- */
  .gorsel-container {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    transition: all 0.3s ease;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
  }
  
  .gorsel {
    width: 100%;
    height: auto;
    object-fit: contain;
    transition: transform 0.3s ease;
    max-height: 100px;
    display: none;
  }
  
  .gorsel.active {
    display: block;
  }
  
  /* --- Büyütme Butonu --- */
  .enlarge-button {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: rgba(255, 255, 255, 0.7);
    border: none;
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .enlarge-button svg {
    width: 24px;
    height: 24px;
  }
  
  /* --- Büyütülmüş Görsel İçin Stil Ayarları --- */
  .gorsel-container.enlarged {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 800px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 90vh;
    padding: 20px;
    overflow-y: auto;
  }
  
  .gorsel-container.enlarged .gorsel {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
    display: none;
  }
  
  .gorsel-container.enlarged .gorsel.active {
    display: block;
  }
  
  /* --- Görsel Açıklaması İçin Stil Ayarları --- */
  .gorsel-aciklama {
    display: none;
  }
  
  .gorsel-aciklama-enlarged {
    background-color: #ffffcc;
    padding: 10px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 28px;
    color: #333;
    border-radius: 5px;
    font-weight: bold;
  }
  
  /* --- Navigasyon Okları --- */
  .nav-arrow {
    position: absolute;
    top: 50%;
    color: white;
    font-size: 50px;
    cursor: pointer;
    user-select: none;
    transition: color 0.2s;
  }
  
  .nav-arrow:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .left-arrow {
    left: 20px;
    transform: translateY(-50%);
  }
  
  .right-arrow {
    right: 20px;
    transform: translateY(-50%);
  }
  
  /* --- Daha Fazla / Daha Az Buton Stilleri --- */
  .daha-fazla-button {
    background-color: #ffffff;
    color: #dc3545;
    border: 0.5px solid #dc3545;
    padding: 5px 10px;
    font-size: 12px; /* Değiştirildi: 10px'den 12px'e */
    cursor: pointer;
    border-radius: 5px;
    margin-top: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    z-index: 1;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .daha-fazla-button:hover {
    background-color: #dc3545;
    color: #ffffff;
  }
  
  .daha-fazla-button::after {
    content: '\25BC';
    margin-left: 5px;
    transition: transform 0.3s, color 0.3s;
  }
  
  .daha-fazla-button.expanded::after {
    content: '\25B2';
  }
  
  .daha-fazla-button:hover::after {
    color: #ffffff;
  }
  
  .daha-fazla-button.expanded:hover {
    background-color: #ffffff;
    color: #dc3545;
  }
  
  .daha-fazla-button.expanded:hover::after {
    color: #dc3545;
  }
  
  /* --- Anlam Ayırıcı --- */
  .anlam-ayirici {
    border: none;
    border-bottom: 1px solid #f4c1c5;
    margin: 15px 0;
    width: 100%;
  }
  
  /* --- Yabancı Kelime İçin Stil --- */
  .yabanci-kelime {
    color: #ff6a00;
  }
  
  /* --- Hover Etkileri --- */
  .anlam-bloku:hover .gorsel-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .gorsel:hover {
    transform: scale(1.05);
  }
  
  .tematik-secimler .tematik-secim-link:hover {
    background-color: #80deea;
  }
  
  .soz-ailesi li:hover {
    color: #27ae60;
  }
  
  /* --- Responsive Tasarım --- */
  @media (max-width: 1200px) {
    #result-wrapper {
      margin-right: 5px; /* Değiştirildi: 40px'den 5px'e */
      padding-right: 5px; /* Değiştirildi: 20px'den 5px'e */
      justify-content: flex-start; /* justify-content zaten flex-start olarak ayarlandı */
    }
  
    #search-results,
    #soz-ailesi-ana-container {
      max-width: 100%;
      margin-right: 5px; /* Değiştirildi: 0'dan 5px'e */
    }
  
    .madde-bloku,
    .soz-ailesi,
    .turkce-ogren,
    .tema-listesi {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  /* --- Erişilebilirlik Ayarları --- */
  @media (prefers-reduced-motion: reduce) {
    * {
      transition: none !important;
    }
  }
  
  @media (prefers-contrast: high) {
    body {
      background: #fff;
      color: #000;
    }
  
    .anlam-ayirici {
      border-color: #000;
    }
  }
  
  
  /* --- Tema Kartları İçin Kare Şekil --- */
  .tema-card .skill-circle {
    width: 100px;
    height: 100px;
    border-radius: 0;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 6px;
  }
  
  /* Tema Kartları İçin Görsel Ayarları */
  .tema-card .skill-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
  }
  
  /* Tema Kartları Hover Efekti */
  .tema-card:hover .skill-circle {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  }
  
  /* Türkçe Öğren Kartı Stilini Korumak İçin */
  .turkce-ogren .skill-circle {
    border-radius: 50%;
  }
  
  .turkce-ogren .skill-icon {
    border-radius: 50%;
  }
  
  /* Opsiyonel: Tema Kartları Görsel Hover Efekti */
  .tema-card:hover .skill-icon {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
  
  /* --- Söz Ailesi ve Türkçe Öğren Bölümleri İçin Genel Ayarlar --- */
  /* Bu bölüm daha önce güncellendi, ek bir değişiklik yapılmasına gerek yok */
  
  