:root {
    --primary-red: #d32f2f;
    --primary-blue: #235390;
    --border-color: #e5e7eb;
    --text-color: #333333;
    --search-height: 47px;
    --button-bg-color: #f0f0f0;
    --button-hover-bg-color: #e0e0e0;
    --button-active-bg-color: #d0d0d0;
    --social-icon-color: #333333;
    --social-icon-hover-color: #d32f2f;
    --keyboard-popup-bg: #fffbe6;
    --keyboard-popup-width: 200px;
    --keyboard-popup-padding: 10px;
    --keyboard-popup-border-radius: 8px;
    --keyboard-popup-transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Header Wrapper */
#header {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: relative;
    z-index: 1000;
}

/* Base Styles */
#header .header-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Top Header */
#header .header-top {
    border-bottom: 1px solid var(--border-color);
    padding: 1.5rem 0;
    background-color: #ffffff;
}

/* Left Section - Brand */
#header .left-section {
    display: flex;
    align-items: center;
}

#header .header-brand {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 8px;
}

#header .brand-logo {
    background: var(--primary-red);
    color: white;
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 22px;
    font-weight: 500;
}

#header .brand-text {
    color: var(--text-color);
    font-size: 18px;
}

/* Right Section */
#header .right-section {
    display: flex;
    align-items: center;
    gap: 2rem;
}

/* Navigation Menu */
#header .nav-menu {
    display: flex;
    align-items: center;
    gap: 2rem;
}

#header .nav-link {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-color);
    font-size: 0.95rem;
    text-decoration: none;
    transition: color 0.3s;
}

#header .nav-link:hover {
    color: var(--primary-red);
}

#header .nav-link i {
    font-size: 1rem;
}



/* Sign Up Button */
#header .sign-up {
    padding: 0.5rem 1rem;
    background: transparent;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    color: var(--text-color);
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease;
}

#header .sign-up:hover {
    background: var(--primary-red);
    color: white;
    border-color: var(--primary-red);
}

/* Search Section */
#header .header-search {
    background: var(--primary-blue);
    padding: 0.50rem 0;
    position: relative;
    z-index: 999;
}

#header .search-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
}

/* Search Box */
#header .search-box {
    display: flex;
    align-items: center;
    background: #f6f5f5;
    border-radius: 18px;
    height: var(--search-height);
    position: relative;
    overflow: visible;
    border: 1px solid #ccc;
    box-shadow: inset 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Language Selection */
#header #header-language-select {
    position: relative;
    min-width: 100px;
    z-index: 300;
}

#header .lang-button {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;
    padding: 0 16px;
    border: none;
    background: none;
    color: var(--text-color);
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
}

#header .lang-button i {
    font-size: 12px;
    margin-left: 4px;
}

#header .header-language-options {
    display: none;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    min-width: 140px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    z-index: 300;
    padding: 4px 0;
}

#header #header-language-select.active .header-language-options {
    display: block;
}

#header .header-language-option {
    padding: 8px 16px;
    cursor: pointer;
    color: var(--text-color);
    font-size: 16px;
    background: white;
    transition: background 0.3s;
}

#header .header-language-option:hover {
    background: #f3f4f6;
}

#header .header-language-option.selected[data-value="turkce"] {
    background: var(--primary-red);
    color: white;
}

#header .header-language-option.selected[data-value^="turkce-"] {
    background: var(--primary-blue);
    color: white;
}

/* Vertical Divider */
#header .vertical-divider {
    width: 1px;
    height: 28px;
    background: var(--border-color);
    margin: 0;
}

/* Search Input */
#header #header-search-input {
    flex: 1;
    height: 100%;
    border: none;
    padding: 0 16px;
    font-size: 16px;
    color: var(--text-color);
    min-width: 0;
    background: transparent;
}

#header #header-search-input:focus {
    outline: none;
}

#header #header-search-input::placeholder {
    color: #9ca3af;
    font-size: 16px;
}

/* Search Actions */
#header .search-actions {
    display: flex;
    align-items: center;
    padding-right: 12px;
    gap: 6px;
    position: relative;
    z-index: 250;
}

#header .action-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    color: #6b7280;
    cursor: pointer;
    border-radius: 20px;
    transition: background 0.3s, color 0.3s;
}

#header .action-button:hover {
    background: var(--button-hover-bg-color);
}

/* Keyboard Popup */
#header #header-turkce-karakterler-popup {
    display: none;
    position: absolute;
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    background: var(--keyboard-popup-bg);
    padding: var(--keyboard-popup-padding);
    border-radius: var(--keyboard-popup-border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 300;
    width: var(--keyboard-popup-width);
    opacity: 0;
    visibility: hidden;
    transition: var(--keyboard-popup-transition);
}

#header #header-turkce-karakterler-popup.active {
    display: block;
    opacity: 1;
    visibility: visible;
}

#header .keyboard-buttons {
    display: flex;
    justify-content: center;
    gap: 4px;
}

#header .header-char-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background: white;
    color: var(--text-color);
    font-size: 15px;
    cursor: pointer;
    transition: background 0.3s;
}

#header .header-char-btn:hover {
    background: #f3f4f6;
}

/* Suggestions Container */
#header #suggestions-container {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 200;
    max-height: 300px;
    overflow-y: auto;
}

#header #suggestions-container.header-hidden {
    display: none;
}

#header #suggestions-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

#header .suggestion-item {
    padding: 8px 16px;
    cursor: pointer;
    color: var(--text-color);
    font-size: 16px;
    transition: background 0.3s;
}

#header .suggestion-item:hover {
    background: #f3f4f6;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    #header .brand-text {
        display: none;
    }

    #header .right-section {
        gap: 1rem;
    }

    #header .nav-menu {
        gap: 1rem;
    }

    #header .header-social-icon {
        font-size: 18px;
    }

    #header .sign-up {
        padding: 0.4rem 0.8rem;
        font-size: 14px;
    }

    #header .search-container {
        width: 90%;
    }

    #header #header-turkce-karakterler-popup {
        width: 150px;
        position: fixed;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 480px) {
    #header .social-icons {
        display: none;
    }

    #header .nav-menu {
        gap: 0.8rem;
    }

    #header .nav-link {
        font-size: 0.85rem;
    }
}

